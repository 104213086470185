<template>
  <div>
    <div class="form-box">
      <el-form
        ref="form"
        label-suffix="："
        label-width="150px"
        class="demo-form-inline"
        :model="values"
        :rules="rules"
        :disabled="!isEdit"
        :label-position="labelposition"
      >
        <el-form-item label="机构LOGO" class="text-form-item">
          <img :src="detail.picture || headImage" alt="" class="logo" />
        </el-form-item>
        <el-form-item :label="$t('2573f3a')" class="text-form-item">
          <span class="text-item">{{
            ACCOUNT_TYPE[detail.organizationType] | textFilter
          }}</span>
        </el-form-item>
        <el-form-item :label="$t('fafa54b')" class="text-form-item">
          <span class="text-item">{{ detail.name | textFilter }}</span>
        </el-form-item>
        <el-form-item :label="$t('a27362a')" class="text-form-item">
          <span class="text-item">{{ detail.legalPerson | textFilter }}</span>
        </el-form-item>
        <el-form-item :label="$t('af12ab4')" class="text-form-item">
          <span class="text-item">{{ detail.idCard | textFilter }}</span>
        </el-form-item>
        <el-form-item :label="$t('af975db')" class="text-form-item">
          <span class="text-item">{{ detail.creditCode | textFilter }}</span>
        </el-form-item>
        <el-form-item :label="$t('db8d921')" class="text-form-item">
          <span class="text-item">{{ userInfo.phone | textFilter }}</span>
        </el-form-item>
        <el-form-item :label="$t('1f832ea')" class="text-form-item">
          <span class="text-item">{{
            detail.establishedTime | textFilter
          }}</span>
        </el-form-item>
        <el-form-item :label="$t('4d4e2a6')" class="text-form-item">
          <span class="text-item">{{ detail.scale | textFilter }}</span>
        </el-form-item>
        <el-form-item :label="$t('da5ea34')" class="text-form-item">
          <span class="text-item">{{
            `${detail.province} ${detail.city}`
          }}</span>
        </el-form-item>
        <el-form-item :label="$t('95c9900')" class="text-form-item">
          <span class="text-item">{{ detail.address }}</span>
        </el-form-item>
        <el-form-item
          :label="$t('service.selectionList[1]')"
          class="text-form-item"
          v-if="isChina"
        >
          <span class="text-item">{{ detail.foreignCountry }}</span>
        </el-form-item>
        <el-form-item required :label="$t('003d5ff')" prop="fields">
          <el-select
            v-model="values.fields"
            style="width: 360px"
            multiple
            allow-create
            filterable
            @change="handleFieldChange"
          >
            <el-option
              v-for="item in fieldOptions"
              :key="item.name"
              :value="item.name"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          required
          :label="$t('service.selectionList[0]')"
          prop="serviceAreas"
        >
          <el-select
            v-model="values.serviceAreas"
            style="width: 360px"
            multiple
            allow-create
            filterable
            @change="handleServiceChange"
          >
            <el-option
              v-for="item in serviceAreaList.content"
              :key="item.name"
              :value="item.name"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('890f013')" prop="description">
          <el-input
            v-model="values.description"
            type="textarea"
            placeholder="请输入机构简介"
            style="width: 360px"
            :rows="5"
            :maxlength="1000"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <div class="button-container">
        <div class="buttons">
          <ActionButton
            v-if="!isEdit"
            key="edit"
            class="edit-button"
            @click="isEdit = true"
          >
            {{ $t("7fae2f0") }}
          </ActionButton>
          <ActionButton
            v-if="isEdit"
            key="save"
            style="width: 160px"
            @click="handleClick"
          >
            {{ $t("476103a") }}
          </ActionButton>
          <el-button v-if="isEdit" style="width: 160px" @click="handleCancel">
            {{ $t("6b0feee") }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { ACCOUNT_TYPE } from "@/utils/constants";
import api from "@/api/account";
import headImage from "@/assets/common/head.png";
import ActionButton from "@/components/ActionButton";

export default {
  name: "Infomation",
  components: {
    ActionButton,
  },
  data() {
    return {
      ACCOUNT_TYPE,
      headImage,
      avatarUrl: "",
      isEdit: false,
      values: {},
      rules: {
        fields: [{ required: true, message: "请选择擅长领域" }],
        serviceAreas: [
          { required: true, message: this.$t("service.selectionList[0]") },
        ],
        description: [{ required: true, message: "请输入机构简介" }],
      },
      detail: {},
      isChina: true,
      labelposition: localStorage.getItem("lang") === "zh" ? "right" : "top",
    };
  },
  computed: {
    ...mapGetters(["userInfo", "dictionaries", "serviceAreaList"]),
    fieldOptions() {
      const dicts = this.dictionaries;
      const type = this.userInfo.userType;
      const dic =
        type === "LAW_OFFICE"
          ? dicts["BUSINESS_TYPE"]
          : dicts["TRANSLATION_BUSINESS_TYPE"];
      return dic ? dic.list : [];
    },
  },
  beforeUpdate() {
    this.labelposition =
      localStorage.getItem("lang") === "zh" ? "right" : "top";
  },
  mounted() {
    this.getServiceAreaList({
      type: "BUSINESS_TYPE",
    });
    if (this.userInfo.userType === "LAW_OFFICE") {
      this.getDictionary({ type: "BUSINESS_TYPE" });
    } else {
      this.getDictionary({ type: "TRANSLATION_BUSINESS_TYPE" });
    }
    api.getOrgIntro().then((res) => {
      if (res) {
        this.detail = { ...res };
        this.isChina = res.countryId === 1;
        // debugger
        this.values = {
          description: res.description,
          fields: res.field ? res.field.split(",") : [],
          serviceAreas: res.serviceArea ? res.serviceArea.split(",") : [],
        };
        this.initValues = {
          description: res.description,
          fields: res.field ? res.field.split(",") : [],
          serviceAreas: res.serviceArea ? res.serviceArea.split(",") : [],
        };
      }
    });
  },
  methods: {
    ...mapActions(["getDictionary", "getOrgIntro", "getServiceAreaList"]),
    handleFieldChange(value) {
      this.values.fields = value;
      this.values.field = value.join(",");
    },
    handleClick() {
      this.$refs["form"].validate((valid) => {
        console.log(this.values, valid);
        if (valid) {
          // api.update(this.$refs['form'].values, this.userInfo.userType)
          api.updateOrgIntro(this.values).then((res) => {
            this.isEdit = false;
            this.$message.success("修改成功");
            this.getOrgIntro();
          });
        }
      });
    },
    handleServiceChange(value) {
      this.values.serviceAreas = value;
      this.values.serviceArea = value.join(",");
    },
    reset() {
      this.values = this.initValues;
    },
    handleCancel() {
      this.isEdit = false;
      this.reset();
    },
  },
};
</script>

<style scoped lang="less">
.form-box {
  position: relative;
  margin-top: 30px;
  padding: 0 160px;
}
.edit-button {
  width: 360px;
  // margin-right: 48px;
}
.button-container {
  text-align: right;
  margin-right: 89px;
}
.button-container.align-center {
  text-align: center;
  margin-right: 0;
}
.buttons {
  width: 360px;
  display: inline-flex;
  justify-content: space-between;
}
.text-form-item {
  margin-bottom: 0;
}
.logo {
  width: 200px;
}
</style>
