<template>
  <el-form
    ref="form"
    label-suffix="："
    label-width="150px"
    class="demo-form-inline"
    :model="values"
    :rules="rules"
  >
    <el-form-item required :label="$t('a68c1d6')" prop="name">
      <el-input
        v-model="values.name"
        :placeholder="$t('7ee8546')"
        style="width: 360px"
      />
    </el-form-item>
    <el-form-item required :label="$t('8d69c5e')" prop="workingTime">
      <el-date-picker
        v-model="values.workingTime"
        type="date"
        placeholder="选择日期"
        style="width: 360px"
        :picker-options="pickerOptions"
      />
    </el-form-item>
    <el-form-item required :label="$t('003d5ff')" prop="fields">
      <el-select
        v-model="values.fields"
        :placeholder="$t('54b3565')"
        style="width: 360px"
        multiple
        allow-create
        filterable
        @change="handleFieldChange"
      >
        <el-option
          v-for="item in fieldOptions"
          :key="item.name"
          :value="item.name"
          :label="item.name"
        />
      </el-select>
    </el-form-item>
    <el-form-item required :label="$t('4b4d5e8')" prop="licenseNumber">
      <el-input
        v-model="values.licenseNumber"
        :placeholder="$t('d30063f')"
        style="width: 360px"
      />
    </el-form-item>
    <el-form-item :label="$t('a5134bb')" prop="description">
      <el-input
        v-model="values.description"
        type="textarea"
        placeholder="请输入律师简介"
        style="width: 360px"
        :rows="5"
        :maxlength="1000"
        show-word-limit
      />
    </el-form-item>
    <el-form-item :label="$t('c647f91')" prop="picture">
      <el-upload
        class="avatar-uploader"
        :headers="headers"
        :action="actionUrl"
        :multiple="false"
        :show-file-list="false"
        :on-success="uploadSuccess"
        :on-remove="handleRemove"
        :before-upload="beforeUpload"
        :file-list="fileList"
        :accept="imageMimeType"
      >
        <img
          v-if="values.picture"
          :key="values.picture"
          :src="values.picture"
          class="avatar"
        />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <div class="buttons">
        <el-button style="width: 160px" type="primary" @click="handleSubmit">
          {{ $t("476103a") }}
        </el-button>
        <el-button style="width: 160px" @click="handleCancel">
          {{ $t("6b0feee") }}
        </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { getToken } from "@/utils/auth";
import validation, { isImage, imageMimeType } from "@/utils/validation";
const actionUrl = "/api/frLegalPlatform/common/qiniu/upload";

export default {
  name: "MemberForm",
  props: {
    initialValues: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      imageMimeType,
      values: {
        picture: "",
        licenseNumber: undefined,
        name: undefined,
        workingTime: undefined,
        yearsOfPractice: undefined,
        fields: undefined,
        description: undefined,
      },
      rules: {
        name: [
          { required: true, message: this.$t("7ee8546") },
          { validator: validation.name },
        ],
        description: [{ required: true, message: "请输入律师简介" }],
        workingTime: [{ required: true, message: "请选择从业时间" }],
        fields: [{ required: true, message: this.$t("54b3565") }],
        licenseNumber: [
          { required: true, message: this.$t("d30063f") },
          { validator: validation.licenseNumber },
        ],
      },
      headers: {
        accessToken: getToken(),
      },
      fileList: [],
      actionUrl,
      pickerOptions: {
        disabledDate(date) {
          const today = new Date();
          today.setHours(23);
          return date > today;
        },
      },
    };
  },
  computed: {
    ...mapGetters(["dictionaries", "userInfo"]),
    fieldOptions() {
      const dicts = this.dictionaries;
      const type = this.userInfo.userType;
      const dic =
        type === "LAW_OFFICE"
          ? dicts["BUSINESS_TYPE"]
          : dicts["TRANSLATION_BUSINESS_TYPE"];
      return dic ? dic.list : [];
    },
  },
  mounted() {
    const values = this.initialValues;
    this.values.picture = values.picture;
    this.values.fields = values.field ? values.field.split(",") : undefined;
    this.values.licenseNumber = values.licenseNumber;
    this.values.name = values.name;
    this.values.workingTime = values.workingTime;
    this.values.yearsOfPractice = values.yearsOfPractice;
    this.values.description = values.description;
  },
  methods: {
    ...mapActions(["getDictionary"]),
    handleFieldChange(value) {
      this.values.fields = value;
      this.values.field = value.join(",");
    },
    uploadSuccess(response, file, fileList) {
      this.handleRemove();
      this.values.picture = response.data.url;
    },
    handleRemove() {
      this.values.businessLicense = undefined;
    },
    beforeUpload(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt10M = file.size / 1024 / 1024 < 10;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isImage(file.type)) {
        this.$message.error("只能上传jpg和png格式的图片");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB!");
      }
      return isLt10M;
    },
    handleSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$emit("submit", this.values);
        }
      });
    },
    handleCancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped lang="less">
.avatar-uploader {
  /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
