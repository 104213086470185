<template>
  <Card
    :container-style="{ padding: '0' }"
    :body-style="{ height: '100%' }"
    no-line
  >
    <template slot="header"> {{ $t("ec7377d") }} </template>
    <template v-slot:actions>
      <el-button
        v-if="activeName === 'member'"
        style="
          height: 32px;
          padding: 8px 22px;
          border-color: rgba(0, 164, 255, 1);
        "
        @click="addMember"
      >
        <!-- <Icon :src="sendImage" size="12" /> -->
        <i class="el-icon-plus" style="color: rgba(0, 164, 255, 1)"></i>
        <span
          style="
            margin-left: 10px;
            color: rgba(0, 164, 255, 1);
            font-size: 14px;
          "
          >{{ $t("d30f7cf") }}</span
        >
      </el-button>
    </template>
    <el-tabs v-model="activeName" class="tab" @tab-click="handleClick">
      <el-tab-pane :label="$t('325a6cb')" name="info">
        <Infomation />
      </el-tab-pane>
      <el-tab-pane :label="$t('3d2f9c3')" name="member">
        <Member ref="member" />
      </el-tab-pane>
    </el-tabs>
  </Card>
</template>

<script>
import Card from "@/components/Card";
import Infomation from "./info";
import Member from "./member";

export default {
  name: "Organization",
  components: {
    Card,
    Infomation,
    Member,
  },
  data() {
    return {
      activeName: "info",
    };
  },
  methods: {
    handleClick() {},
    addMember() {
      this.$refs["member"].openForm();
    },
  },
};
</script>

<style scoped lang="less">
.tab {
  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
  }
  /deep/ .el-tabs__nav-scroll {
    padding: 0 30px;
  }
  /deep/ .el-tabs__item {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
