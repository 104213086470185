<template>
  <div>
    <el-table
      ref="table"
      :data="data || []"
      style="width: 100%"
      :cell-class-name="cellClassName"
      row-key="id"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="60" align="right" />
      <el-table-column
        prop="date"
        :label="$t('285e24a')"
        width="50"
        :formatter="(_, __, ___, i) => i + 1"
      />
      <el-table-column prop="title" :label="$t('db16d47')">
        <template slot-scope="scope">
          <img
            :src="scope.row.picture || headImage"
            style="height: 56px; width: 42px"
          />
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="$t('a68c1d6')" width="140" />
      <el-table-column
        prop="yearsOfPractice"
        :label="$t('6608201')"
        width="80"
      />
      <el-table-column prop="field" :label="$t('003d5ff')" width="180" />
      <el-table-column
        prop="licenseNumber"
        :label="$t('4b4d5e8')"
        width="160"
      />
      <el-table-column
        prop="type"
        :label="$t('f8de4c5')"
        :formatter="(_, __, value) => value"
      >
        <template slot-scope="scope">
          <a
            v-if="scope.row.source === 'INPUT'"
            class="text-button"
            style="color: #42a5f5"
            @click="editMember(scope.row)"
            >{{ $t("a084558") }}</a
          >
          <span class="text-separate"></span>
          <el-popconfirm
            :title="$t('8d1023d')"
            @confirm="deleteAMember(scope.row)"
          >
            <a slot="reference" class="text-button" style="color: #f5222d">{{
              $t("8018b9c")
            }}</a>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <div>
        <el-checkbox
          :label="$t('84a6438')"
          class="checkbox"
          :value="selected.length === data.length"
          @change="toggleSelection"
        />
        <el-popconfirm :title="$t('04ed494')" @confirm="deleteSelected">
          <a slot="reference" class="button">
            <Icon :src="deleteImage" size="14" />
            <span style="margin-left: 5px; vertical-align: middle">
              {{ $t("04ed494") }}
            </span>
          </a>
        </el-popconfirm>
      </div>
      <Pagination :page-info="pageInfo" @changePage="changePage" />
    </div>
    <el-dialog
      :visible.sync="formVisible"
      :title="formMode === 'ADD' ? $t('d30f7cf') : $t('5841fe8')"
      @close="closeForm"
    >
      <member-form
        v-if="formVisible"
        :initial-values="editData"
        @submit="handleSubmit"
        @cancel="handleCancel"
      />
    </el-dialog>
  </div>
</template>

<script>
// import "./index.less";
import { mapGetters, mapActions } from "vuex";

import Pagination from "@/components/Pagination";
import Icon from "@/components/Icon";
import MemberForm from "./memberForm";

import deleteImage from "@/assets/common/delete_active.png";
import headImage from "@/assets/service/icon0.png";

import api from "@/api/organization";

export default {
  name: "Member",
  components: {
    Pagination,
    Icon,
    MemberForm,
  },
  props: {},
  data() {
    return {
      deleteImage,
      headImage,
      formVisible: false,
      formMode: "ADD",
      data: [],
      selected: [],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total: 0,
      },
      editData: {},
    };
  },
  computed: {
    ...mapGetters(["dictionaries", "userInfo"]),
  },
  mounted() {
    if (this.userInfo.userType === "LAW_OFFICE") {
      this.getDictionary({ type: "BUSINESS_TYPE" });
    } else {
      this.getDictionary({ type: "TRANSLATION_BUSINESS_TYPE" });
    }
    this.getList();
  },
  methods: {
    ...mapActions(["getAreaTree", "getDictionary"]),
    cellClassName({ column }) {
      if (column.property === "title") {
        return "table-title";
      }
      return "";
    },
    handleReset() {
      this.getList();
    },
    changePage(page) {
      this.pageInfo.currentPage = page;
      this.selected = [];
      this.getList();
    },
    handleSelectionChange(a) {
      this.selected = [...a];
    },
    getList(params = {}) {
      api
        .get({
          size: this.pageInfo.size,
          page: this.pageInfo.currentPage,
          ...params,
        })
        .then((res) => {
          if (res) {
            this.data = res.content;
            // this.pageInfo.total = 100
            this.pageInfo.total = res.page.total;
          }
        });
    },
    editMember(data) {
      this.editData = { ...data };
      this.formMode = "EDIT";
      this.formVisible = true;
    },
    deleteAMember(row) {
      this.delete([row.id]);
    },
    deleteSelected() {
      this.delete(this.selected.map((i) => i.id));
    },
    delete(ids) {
      api.delete(ids).then((res) => {
        if (res) {
          this.$message.success(this.$t("6b1fd67").toString());
          this.pageInfo.currentPage = 1;
          this.selected = [];
          this.getList();
        }
      });
    },
    toggleSelection(state) {
      const isAllSelected = this.selected.length === 10;
      if (isAllSelected && state) return;
      this.$refs["table"].toggleAllSelection();
    },
    // --------------------------------------------------------
    // 表单操作方法
    // --------------------------------------------------------
    handleCancel() {
      this.editData = {};
      this.formVisible = false;
    },
    openForm() {
      this.formMode = "ADD";
      this.formVisible = true;
    },
    closeForm() {
      this.formVisible = false;
      this.editData = {};
    },
    handleSubmit(values) {
      if (this.formMode === "ADD") {
        api.add(values).then((res) => {
          if (res) {
            this.closeForm();
            this.getList();
          }
        });
      } else {
        api.put(this.editData.id, values).then((res) => {
          if (res) {
            this.closeForm();
            this.getList();
          }
        });
      }
      this.editData = {};
    },
  },
};
</script>
<style scoped lang="less">
.table-title {
  cursor: pointer;
}
.table-title:hover {
  color: rgba(0, 164, 255, 1);
}
.button {
  color: rgba(102, 102, 102, 1);
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
}
.text-separate {
  display: inline-block;
  height: 13px;
  margin: 0 10px;
  border-left: 1px solid rgba(232, 232, 232, 1);
  vertical-align: middle;
}
.checkbox {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px !important;
  /deep/ .el-checkbox__label {
    color: rgba(102, 102, 102, 1);
    font-weight: normal;
  }
}
</style>
